import { isUUID, isString } from 'class-validator';
import { debounce } from 'debounce';
import io from 'socket.io-client';

import config from '@/config/config';
import store from '@/store/index';
import { UserRole, databaseActions } from '@satellite/../nova/core';
import { LAST_CRUD_ACTION_KEY } from '@satellite/plugins/util';

class SocketPlugin {
  socket;
  app;
  eventsBound = false;
  // Had to reduce this delay from 500 due to it being user-perceptable.
  debounceDelayMs = 100;
  previousEventKey = null;
  debounceData = {
    count: 0,
    ids: []
  };

  actions = databaseActions;

  entities = [
    'Warehouse',
    'WarehouseGroup',
    'User',
    'Appointment',
    'LoadType',
    'Dock',
    'Org',
    'Trigger',
    'FormField',
    'Field',
    'Flow',
    'CustomFormData',
    'AssetVisit',
    'AssetVisitEvent'
  ];

  connectionStatuses = {
    CONNECTED: 'connected',
    RECONNECTING: 'reconnecting',
    DISCONNECTED: 'disconnected'
  };

  // These entities will react on socket events when the
  // logged user role matches with the key.
  // When the role is not in the list, all entities
  // will be used.
  userEntities = {
    [UserRole.GOD]: ['Org', 'User'],
    [UserRole.INTERNAL]: ['Org', 'User']
  };

  constructor(app) {
    this.app = app;
    this.debounceEvent = debounce(this.handleSocketEvent, this.debounceDelayMs);
  }

  connect(token) {
    this.disconnect();

    if (!token) {
      return;
    }

    this.socket = io(`${config.subspace_url}?token=${token}`, { transports: ['websocket'] });
    this.bindConnectEvent();
    this.socket.connect();
  }

  disconnect() {
    if (this.socket?.connected) {
      this.socket.disconnect();
      this.emitConnectionStatusEvent(this.connectionStatuses.DISCONNECTED);
      this.eventsBound = false;
    }
  }

  bindConnectEvent() {
    this.socket.once('connect', () => {
      this.emitConnectionStatusEvent(this.connectionStatuses.CONNECTED);
      if (this.socket.connected && !this.eventsBound) {
        this.bindEvents();
      }
    });

    // Handle Disconnection Events
    for (const event of ['error', 'reconnect_error', 'reconnect_failed']) {
      this.socket.on(event, () =>
        this.emitConnectionStatusEvent(this.connectionStatuses.DISCONNECTED)
      );
    }

    this.socket.on('reconnect', () =>
      this.emitConnectionStatusEvent(this.connectionStatuses.CONNECTED)
    );

    this.socket.on('reconnect_attempt', () =>
      this.emitConnectionStatusEvent(this.connectionStatuses.RECONNECTING)
    );
  }

  getActionDisplayName(action) {
    return this.actions[action].displayName;
  }

  getActionPastTense(action) {
    return this.actions[action].pastTense;
  }

  // Allow socket events to be passed through the global "eventHub"
  // Notice the payload.id on the event key, this allows specific detail pages to respond to changes to the entity they own
  // Without having to perform logic to detect if they are the owner
  bindEvents() {
    const events = this.getBindableEvents();

    events.forEach(event => {
      this.socket.on(event.eventKey, payload => {
        if (event.eventKey !== this.previousEventKey) {
          this.debounceEvent.flush();
          this.resetDebouncing();
        }

        this.debounceData.count++;
        this.debounceData.ids.push(payload.id);
        this.debounceEvent(event, payload);

        this.previousEventKey = event.eventKey;
      });
    });

    // Pass the websocket heartbeat through the EventHub
    this.socket.on('heartbeat', payload => {
      this.app.$eventHub.$emit('heartbeat', payload);
    });

    this.eventsBound = true;
  }

  emitConnectionStatusEvent(status) {
    this.app.$eventHub.$emit('websocket-connection-status-change', status);
  }

  resetDebouncing() {
    this.debounceData = {
      count: 0,
      ids: []
    };
  }

  handleSocketEvent(event, payload) {
    // Events happening on these entities are
    // propagated without the $eventHub and depends on the
    // store method implementation behavior to react
    // to the event.
    const globalEventEntities = ['Org'];

    if (this.debounceData.count === 1) {
      this.resetDebouncing();
    }

    payload.debounce = this.debounceData;

    // This event is meant for debugging purposes, ONLY
    this.app.$eventHub.$emit('subspace-event', { payload, event: event });

    if (this.isEventAllowed(event, payload.id, payload.lastChangedBy)) {
      this.app.$eventHub.$emit(`${event.eventKey}-${payload.id}`, payload);
      this.app.$eventHub.$emit(`${event.eventKey}`, payload);
    }

    // This calls straight the store method called handleSocketEvent
    // which bypasses the event hub, meant for entities that should
    // notify on all frontend modules for changes
    if (this.storeModulesSupportEvent(event)) {
      if (globalEventEntities.includes(event.entity)) {
        store.dispatch(`${event.entity}s/handleSocketEvent`, {
          action: event.action,
          data: payload
        });
      }
    }

    this.resetDebouncing();
    this.previousEventKey = null;
  }

  storeModulesSupportEvent(event) {
    return (
      store.modules()[`${event.entity}s`] &&
      store.modules()[`${event.entity}s`]['actions']['handleSocketEvent']
    );
  }

  /*
   * Check if the event is a self-made event
   * When the logged user has performed the action
   * that triggered the event.
   */
  isSelfMadeEvent(event, entityId, lastChangedBy) {
    let isSelfMade = false;

    const lastCrudAction = sessionStorage.getItem(LAST_CRUD_ACTION_KEY) || ':';
    const [lastCrudEventName, lastCrudEventID] = lastCrudAction.split(':');

    const hasLastEvent = isString(lastCrudEventName) && isUUID(lastCrudEventID) && isUUID(entityId);

    if (hasLastEvent) {
      isSelfMade =
        entityId === lastCrudEventID &&
        event.eventKey.toLowerCase() === lastCrudEventName.toLowerCase() &&
        this.app.$me?.id === lastChangedBy;
    }

    return isSelfMade;
  }

  isEventAllowed(event, entityId, lastChangedBy) {
    if (!isUUID(this.app.$me?.id)) {
      return false;
    }

    if (this.isSelfMadeEvent(event, entityId, lastChangedBy)) {
      return false;
    }

    const allowedEntities = this.userEntities[this.app.$me.role] ?? this.entities;
    return allowedEntities.includes(event.entity);
  }

  getBindableEvents(entityFilter = null) {
    let events = [];

    this.entities.forEach(entity => {
      if (!entityFilter || entity === entityFilter) {
        Object.keys(this.actions).forEach(action => {
          events.push({ eventKey: `${action}-${entity}`, action, entity });
        });
      }
    });

    return events;
  }
}

export default SocketPlugin;
